html {
  font-size: 90%;
}

@media (width >= 700px) {
  html {
    font-size: 100%;
  }
}

body {
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
}

h1 {
  margin: 1rem 0;
  font-family: heading;
}

h2, h3, h4, h5 {
  color: #ff6002;
}

h1 {
  color: #3a28d4;
  font-size: 2.2rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.4rem;
}

p, ul, ol {
  margin-bottom: 1rem;
}

b {
  font-weight: 600;
}

a {
  cursor: pointer;
}

a.anchor {
  color: #3a28d4;
  font-size: inherit;
  font-weight: normal;
  text-decoration: underline;
  transition: all .6s cubic-bezier(.22, 1, .36, 1);
}

a.anchor:hover {
  color: #ff6002;
}

a.anchor:visited {
  color: #3a28d4;
}

.button--text {
  color: #ff6002;
  text-decoration: underline;
  transition: all .6s cubic-bezier(.22, 1, .36, 1);
  position: relative;
  overflow: hidden;
}

.button--text:hover {
  cursor: pointer;
  color: #3a28d4;
}

ol, ul {
  padding-left: var(--gutter-large);
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

del {
  text-decoration: line-through;
}

strong {
  font-weight: 800;
}

pre {
  white-space: pre;
  font-family: monospace;
}

em {
  font-style: italic;
}

hr {
  margin: var(--gutter-large) 0 4rem;
  border-bottom: 2px solid #bebebe;
}

a.button, button.button {
  cursor: pointer;
  color: #fff;
  margin-top: .75rem;
  padding: .75rem 1.5rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .6s cubic-bezier(.22, 1, .36, 1);
  display: inline-flex;
}

a.button:hover, button.button:hover {
  text-decoration: none;
}

a.button--primary, button.button--primary {
  color: #fff;
  background: #3a28d4;
}

a.button--primary:hover, button.button--primary:hover {
  background: #ff6002;
}

a.button--secondary, button.button--secondary {
  color: #fff;
  background: #ff6002;
}

a.button--secondary:hover, button.button--secondary:hover {
  background: #3a28d4;
}

.blue-button-style, button.button#notify, button.button#button_signin, button.button.b_standard {
  color: #fff;
  background: #3a28d4;
}

.orange-button-style, button.button#button_account, button.button.b_small, button.button.b_checkout, .grey-button-style {
  color: #fff;
  background: #ff6002;
}

button.button {
  justify-content: center;
  align-items: center;
  min-height: 3rem;
  display: flex;
}

button.button.b_basket {
  text-transform: uppercase;
  padding: 2rem;
  font-size: 1.1rem;
}

button.button.b_small {
  text-align: center;
  width: auto;
  height: auto;
  padding: 1rem;
  font-size: .9rem;
  display: inline-block;
}

.quickview.button.b_standard {
  width: 130px;
  height: auto;
}

.center section > h1:first-of-type {
  text-align: center;
  margin-top: 1rem !important;
}

input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
  border: 1px solid #000;
  width: 1rem;
  height: 1rem;
  margin: 0;
  transition: all .6s cubic-bezier(.22, 1, .36, 1);
}

input[type="checkbox"]:checked, input[type="radio"]:checked {
  background: #ff6002;
}

label {
  cursor: pointer;
}

select {
  align-items: center;
  display: flex;
}

.refine input[type="checkbox"] {
  margin-right: .5rem;
}

.grid.down.apart_s > div {
  align-items: center;
  display: flex;
}

.grid.down.apart_s > div input {
  margin-right: .5rem;
}

.navigation_bar .navigation_links > ul {
  position: relative;
}

.navigation_bar .dropdown {
  position: static;
}

.navigation_bar .dropdown_menu {
  background: none;
}

.navigation_bar .dropdown_menu a {
  font-size: 1.1rem;
}

.navigation_bar .dropdown_menu:before {
  content: "";
  z-index: 2;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMDMuODkiIGhlaWdodD0iMTQ1LjI2IiBjbGFzcz0ibG9nbyIgdmlld0JveD0iMCAwIDUzLjk1IDM4LjUiPjxnIGFyaWEtbGFiZWw9IkZSRUVXQVkiPjxwYXRoIGZpbGw9IiNmZjYwMDIiIGQ9Ik0tLjE4LjAxaDcuOTVsMTYuMzggMjUuODRWNC4zN2MwLS42Mi4xLTEuMi4zNC0xLjcyLjIyLS41NS41My0xLjAzLjkzLTEuNDIuMzktLjQuODUtLjY5IDEuMzctLjg4QTQuMDggNC4wOCAwIDAgMSAyOC40NiAwYTQuMzcgNC4zNyAwIDAgMSAzLjgzIDIuM2wxNC45IDIzLjU1VjBoNi42N3YzNS4yMWMwIDEuMDEtLjI2IDEuODEtLjc4IDIuNC0uNTMuNi0xLjIyLjg5LTIuMDYuODktLjc2IDAtMS40OC0uMTUtMi4xNi0uNDQtLjY2LS4zLTEuMjEtLjgtMS42Ny0xLjUyTDMwLjggMTEuOTF2MjMuM2MwIDEtLjI2IDEuOC0uNzggMi40LS41My41OS0xLjIyLjg4LTIuMDYuODgtLjc2IDAtMS40OC0uMTUtMi4xNi0uNDQtLjY2LS4zLTEuMjEtLjgtMS42Ni0xLjUyeiIgY2xhc3M9ImxvZ28tLW9yYW5nZSIvPjxwYXRoIGZpbGw9IiMzYTI4ZDQiIGQ9Ik0xNy4yMiA2Ljc5SDYuNXY5Ljk2aDkuMDJ2Ni42N0g2LjQ5djE0Ljk1SC0uMThWLjEzaDE3LjR6IiBjbGFzcz0ibG9nby0tYmx1ZSIvPjwvZz48L3N2Zz4=");
  background-size: 2.5rem 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
}

.navigation_bar .dropdown_menu:after {
  content: "";
  z-index: -1;
  filter: drop-shadow(0 0 10px #ff6002);
  background: #fff;
  border-bottom-right-radius: 2rem;
  width: 96%;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.navigation_bar .dropdown_menu:before, .navigation_bar .dropdown_menu:after {
  display: none;
}

@media (width >= 960px) {
  .navigation_bar .dropdown_menu:before, .navigation_bar .dropdown_menu:after {
    display: block;
  }

  .navigation_bar .dropdown_menu .dropdown_wrap {
    background: #fff;
    border-bottom-right-radius: 2rem;
    padding: 2rem 2rem 3rem;
    position: relative;
  }

  .navigation_bar .dropdown_menu .dropdown_wrap:after {
    content: "";
    background: #3a28d4;
    height: 1px;
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    right: 2rem;
  }
}

.option_icons .option_long.selectable {
  border: 1px solid #bebebe;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  transition: all .6s cubic-bezier(.22, 1, .36, 1);
  display: flex;
}

.option_icons .option_long.selectable:hover, .option_icons .option_long.selectable.selected {
  border: 1px solid #000;
}

.option_icons .strike {
  z-index: 1;
  opacity: .75;
  background-image: none;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
}

.option_icons .strike:before {
  content: "";
  background: red;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%)rotateZ(45deg);
}

.option_icons.swatch > div.option_color, .option_icons.swatch > div.option_image {
  border-radius: .5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
}

.option_icons > div.option_color, .option_icons > div.option_image {
  border-radius: .5rem;
}

.option_icons > div.option_color.selectable.selected, .option_icons > div.option_image.selectable.selected {
  border: 1px solid #000;
}

#product_list_inner .inner_title a {
  font-size: 1rem;
}

.product_info .prd-cta {
  background: none;
}

.product_info .basket_quantity {
  border-radius: .5rem;
}

.product_info .quantity_add, .product_info .quantity_sub {
  width: 8rem;
}

.product_info h1 {
  font-size: 1.8rem;
}

.product_info h1 a, .product_info h1 a:hover {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

.product_info button.button.b_basket {
  background: #ff6002;
  justify-content: center;
  align-items: center;
  display: flex;
}

.product_info #additions .apart_s {
  column-gap: 0;
}

.product_info #additions .qq-upload-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.product_detail .stock-helper-text {
  color: #ff6002;
  height: 1rem;
  margin: 0;
}

.product_detail .product_additional {
  font-size: 1.2rem;
}

#discount, .product_price {
  width: 100%;
}

.logo-printing__instructions article {
  background: #f5f5f5;
  margin: 0 0 1.5rem 1.5rem;
  padding: 1.5rem 2rem 1.5rem 3rem;
  position: relative;
}

.logo-printing__instructions article:after {
  content: "";
  z-index: -1;
  background: #3a28d4;
  width: 4px;
  height: 110%;
  position: absolute;
  top: calc(50% + 1rem);
  left: -4px;
}

.logo-printing__instructions article:last-child:after {
  display: none;
}

.logo-printing__instructions article h3 {
  margin: 0;
}

.logo-printing__instructions article p {
  margin-top: 0;
}

.logo-printing__instructions article > span {
  color: #fff;
  background: #3a28d4;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3.5rem;
  font-size: 2rem;
  line-height: 1;
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(calc(-50% - 4px), -50%);
}

.product_discount {
  background: #f5f5f5;
  padding: .5rem 1rem;
  font-size: .9rem;
}

.product_discount .discount-table ul {
  padding: 0;
  list-style: none;
}

.product_discount .discount-table ul li {
  justify-content: space-between;
  align-items: center;
  padding: .25rem 0;
  display: flex;
}

.product_discount .discount-table ul li > div {
  align-items: center;
  display: flex;
}

.product_discount .discount-table__price {
  font-size: 1.1rem;
}

.product_discount .discount-table__percentage {
  color: #fff;
  background: #3a28d4;
  border-radius: 1rem;
  margin-left: 1rem;
  padding: .1rem .75rem;
  font-size: .7rem;
}

.product_discount .discount-table__sup {
  color: #3b3b3b;
}

.inner_description p {
  font-size: .9rem;
}

.copyright a span {
  display: none;
}

.cb_title {
  font-family: heading;
}

.block.block_latest_products {
  margin-top: 4rem;
}

.basket_quantity .quantity_sub, .basket_quantity .quantity_add {
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  display: flex;
}

.basket_quantity .pquantity {
  color: #3a28d4;
}

.product_selling {
  align-items: end;
  display: flex;
}

.product_selling #price_selling {
  margin-right: 1rem;
}

.product_selling .selling_price {
  color: #ff6002;
  font-size: 1.6rem;
}

#cb_id_CONTENT > table.tables td:nth-child(2) {
  display: none;
}

.container {
  padding: 0 var(--gutter-small);
  flex-direction: column;
  width: 1200px;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

@media (width >= 700px) {
  .container {
    padding: 0 var(--gutter-large);
  }
}

.container--narrow {
  width: 50rem;
}

.section {
  background: #fff;
  min-height: 90vh;
  padding: 8rem 0;
}

@media (width >= 700px) {
  .section {
    min-height: 40vh;
    margin-top: 8rem;
  }
}

@media (width >= 960px) {
  .section {
    min-height: 75vh;
  }
}

.section--image {
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
}

.section--image .section__content {
  padding: 4rem var(--gutter-large);
  z-index: 1;
  background: #fff;
  flex-direction: column;
  justify-content: center;
  max-width: 40rem;
  margin: -4rem 0 0;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 2rem #bebebe;
}

@media (width >= 700px) {
  .section--image .section__content {
    min-height: 50vh;
    padding: 4rem 2rem;
  }
}

@media (width >= 960px) {
  .section--image .section__content {
    width: 50%;
    margin: 0 0 0 2rem;
    padding: 4rem 3rem;
  }
}

@media (width >= 1200px) {
  .section--image .section__content {
    padding: 4rem;
  }
}

.section--image .section__content-cta {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.section--image .section__content-cta .button {
  width: fit-content;
}

.section--image .section__content-cta .anchor {
  margin-top: var(--gutter-small);
}

@media (width >= 400px) {
  .section--image .section__content-cta .anchor {
    margin-top: .5rem;
    margin-left: var(--gutter-small);
  }

  .section--image .section__content-cta {
    flex-direction: row;
  }
}

.section--image .section__image {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 50vw;
}

@media (width >= 960px) {
  .section--image .section__image {
    width: 50%;
    height: auto;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .section--image-reversed .section__content {
    margin: 0 2rem 0 auto;
  }
}

.section--image-reversed .section__image {
  left: 0;
  right: auto;
}

.heading h2, .heading h3 {
  font-family: heading;
  position: relative;
}

.heading--align-center {
  text-align: center;
}

.heading--align-right {
  text-align: right;
}

.anniversary-banner {
  background: #000;
  padding: 4rem 0;
  position: relative;
}

.anniversary-banner__inner {
  align-items: center;
  display: flex;
}

.anniversary-banner__inner > div {
  display: flex;
  position: relative;
}

.anniversary-banner h2 {
  margin: 0;
  padding: 0 2rem 2.5rem 0;
  font-family: heading;
  font-size: 8rem;
  line-height: 1;
}

@media (width >= 700px) {
  .anniversary-banner h2 {
    padding: 0 4rem 3rem 0;
    font-size: 11rem;
  }
}

@media (width >= 960px) {
  .anniversary-banner h2 {
    font-size: 14rem;
  }
}

.anniversary-banner .anniversary-banner__prefix, .anniversary-banner .anniversary-banner__suffix {
  color: #fff;
  margin: 0;
  position: absolute;
}

.anniversary-banner .anniversary-banner__prefix {
  color: #3a28d4;
  font-size: 2rem;
  top: 26px;
  right: -2.25rem;
  transform: rotate(-90deg);
}

@media (width >= 700px) {
  .anniversary-banner .anniversary-banner__prefix {
    font-size: 2.7rem;
    top: 40px;
    right: -2rem;
  }
}

@media (width >= 960px) {
  .anniversary-banner .anniversary-banner__prefix {
    font-size: 3.4rem;
    top: 55px;
    right: -3.5rem;
  }
}

.anniversary-banner .anniversary-banner__suffix {
  text-align: center;
  color: #fff;
  font-size: 2.3rem;
  bottom: 0;
  left: 0;
  right: 0;
}

.anniversary-banner .anniversary-banner__suffix span, .anniversary-banner .anniversary-banner__suffix i {
  font-style: normal;
  transition: all .6s cubic-bezier(.22, 1, .36, 1);
  display: inline-block;
}

.anniversary-banner .anniversary-banner__suffix span {
  -webkit-text-stroke: 1px #3a28d4;
}

.anniversary-banner .anniversary-banner__suffix i, .anniversary-banner .anniversary-banner__suffix:hover span {
  -webkit-text-stroke: 1px #ff6002;
}

.anniversary-banner .anniversary-banner__suffix:hover i {
  -webkit-text-stroke: 1px #3a28d4;
}

@media (width >= 700px) {
  .anniversary-banner .anniversary-banner__suffix {
    font-size: 3.2rem;
    left: -11px;
  }
}

@media (width >= 960px) {
  .anniversary-banner .anniversary-banner__suffix {
    font-size: 4rem;
    bottom: -1rem;
    left: -5px;
  }
}

.brands-strip {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (width >= 700px) {
  .brands-strip {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-bottom: 0;
  }
}

.brands-strip ul {
  grid-template-columns: 1fr 1fr;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  display: grid;
}

@media (width >= 700px) {
  .brands-strip ul {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 65rem;
  }
}

.brands-strip ul li {
  padding: var(--gutter-small);
  opacity: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: all .6s cubic-bezier(.22, 1, .36, 1);
  display: inline-flex;
}

.brands-strip ul li:hover {
  opacity: .5;
}

@media (width >= 700px) {
  .brands-strip ul li {
    padding: var(--gutter-small);
  }
}

@media (width >= 960px) {
  .brands-strip ul li {
    height: calc(6rem + var(--gutter-small) * 2);
    padding: var(--gutter-small) var(--gutter-large);
    width: auto;
  }
}

.brands-strip ul li a {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.brands-strip ul li img {
  width: 70%;
  max-width: 14rem;
  height: auto;
  max-height: 7rem;
}

@media (width >= 960px) {
  .brands-strip ul li img {
    width: 100%;
    max-height: 6rem;
  }
}

.intro {
  background: #fff;
  font-size: 1rem;
  position: relative;
}

.intro__top {
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  overflow: hidden;
  transform: translateY(-95%);
}

.intro__top:before {
  content: "";
  background: linear-gradient(0deg, #fff 10%, #ffffff80 60%, #fff0 100%);
  width: 200vw;
  height: 75%;
  position: absolute;
  bottom: calc(6rem - 1px);
  left: 50%;
  transform: rotate(5deg)translate(-50%);
}

@media (width >= 700px) {
  .intro__top:before {
    bottom: calc(-3rem - 1px);
  }
}

.intro__top:after {
  content: "";
  background: #fff;
  width: 200vw;
  height: 50%;
  position: absolute;
  bottom: 6rem;
  left: 50%;
  transform: rotate(5deg)translate(-50%, 100%);
}

@media (width >= 700px) {
  .intro__top:after {
    bottom: -3rem;
  }
}

.intro__content {
  padding: 0 var(--gutter-small);
  z-index: 2;
  color: #000;
  background: #fff;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  margin: 0 auto;
  font-size: 1.2rem;
  display: flex;
  position: relative;
}

.logo path {
  transition: all .6s cubic-bezier(.22, 1, .36, 1);
}

.logo--orange {
  fill: #ff6002;
}

.logo--blue, .logo:hover .logo--orange {
  fill: #3a28d4;
}

.logo:hover .logo--blue {
  fill: #ff6002;
}

.trade__no-account {
  box-shadow: none;
  margin-top: 1rem;
  display: block;
}

@media (width >= 700px) {
  .trade__no-account {
    margin-top: 0;
    margin-left: 1rem;
    display: inline-block;
  }
}

@media (width >= 960px) {
  .trade__no-account {
    margin: 0 0 0 .75rem;
  }
}

.trade .modal {
  z-index: 100;
  position: fixed;
  inset: 0;
}

.trade .modal__close {
  cursor: pointer;
  z-index: 1;
  color: #3b3b3b;
  padding: 2rem;
  font-weight: 500;
  transition: all .6s cubic-bezier(.22, 1, .36, 1);
  position: absolute;
  top: 0;
  right: 0;
}

.trade .modal__close:hover {
  color: #ff6002;
}

.trade .modal__viel {
  cursor: pointer;
  z-index: 99;
  opacity: .75;
  background: #000;
  position: absolute;
  inset: 0;
}

.trade .modal__content {
  z-index: 101;
  background: #fff;
  width: 100%;
  max-width: 85vw;
  height: auto;
  margin: auto;
  padding: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width >= 400px) {
  .trade .modal__content {
    width: 30rem;
  }
}

.trade .modal__content-buttons {
  justify-content: center;
  display: flex;
}

.form {
  text-align: left;
  width: 100%;
  max-width: 800px;
  margin: 4rem auto;
  padding: 0;
}

@media (width >= 700px) {
  .form {
    padding: 0 var(--gutter-large);
  }
}

.form-fieldset--two-col {
  grid-gap: var(--gutter-small);
  grid-template-columns: 1fr 1fr;
  display: grid;
}

.form__submitted {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25rem;
  margin: 4rem auto;
  padding: 2rem;
  display: flex;
}

.form__submitted p {
  margin: 0;
  font-weight: 500;
}

.form__submitted .round-check-icon {
  width: 10rem;
}

.form__submitted .round-check-icon path {
  fill: green;
}

.form .heading {
  text-align: center;
}

.form h3 {
  margin: 4rem 0 0;
}

.form label {
  color: #3b3b3b;
  cursor: pointer;
  align-items: center;
  margin-bottom: .25rem;
  font-weight: 500;
  line-height: 1.1;
  transition: all .6s cubic-bezier(.22, 1, .36, 1);
  display: flex;
}

.form label span {
  color: #ff6002;
  font-size: 1.5em;
}

.form label p {
  color: #696969;
  font-style: italic;
  font-weight: 400;
  display: block;
}

.form .input, .form textarea {
  box-shadow: none;
  border-bottom: 2px solid #bebebe;
  padding: .5rem;
  font-family: inherit;
  transition: all .6s cubic-bezier(.22, 1, .36, 1);
  background: none !important;
}

.form .input:focus, .form textarea:focus {
  border-bottom: 2px solid #ff6002;
}

.form .input:focus + label, .form textarea:focus + label {
  color: #ff6002;
  font-weight: 600;
}

.form .input::placeholder, .form textarea::placeholder {
  color: #bebebe;
  opacity: .6;
  font-style: italic;
  font-weight: 400;
}

.form .input {
  width: 100%;
}

.form input[type="number"] {
  text-align: center;
}

.form fieldset h3 {
  margin-top: 4rem;
}

.form textarea {
  resize: vertical;
  width: 100%;
  height: 150px;
}

.form .field {
  flex-direction: column-reverse;
  margin-top: 2rem;
  display: flex;
}

.form .field--name {
  display: none;
}

.form .field--checkbox {
  flex-direction: row;
}

.form .field--checkbox label {
  margin: 0;
}

.form .checkbox {
  margin-right: var(--gutter-small);
  cursor: pointer;
  border: 3px solid #000;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  display: flex;
  position: relative;
}

.form .checkbox--checked:after {
  content: "";
  background: #ff6002;
  position: absolute;
  inset: 3px;
}

.prd-page-details {
  min-width: 0;
}

.size-chart {
  text-align: center;
  overflow-x: scroll;
}

.size-chart table {
  min-width: 100%;
  margin-bottom: 4rem;
}

.size-chart table thead {
  background: #f5f5f5;
}

.size-chart table th, .size-chart table td {
  white-space: nowrap;
  padding: .5rem 1rem;
}

.splash {
  padding: 0 var(--gutter-large);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 55vh;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (width >= 700px) {
  .splash {
    padding-top: var(--header-height-large);
  }
}

.splash__video {
  object-fit: cover;
  top: var(--header-height-small);
  z-index: 0;
  object-position: 80%;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
}

@media (width >= 700px) {
  .splash__video {
    top: var(--header-height-large);
    object-position: center;
  }
}

.splash__phone {
  z-index: 2;
  font-size: 1.25rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.splash__phone a {
  color: #000;
  text-decoration: none;
}

.splash__logo {
  z-index: 2;
  width: 30rem;
  max-width: 95%;
  margin: auto;
  position: relative;
}

.splash__logo-sup {
  text-align: center;
  font-size: 3.3vw;
  font-weight: 600;
}

@media (width >= 400px) {
  .splash__logo-sup {
    font-size: 3.2vw;
  }
}

@media (width >= 700px) {
  .splash__logo-sup {
    font-size: 1.5rem;
  }
}

.splash__logo svg {
  filter: drop-shadow(0 0 1rem #fff);
}

.four-oh-four {
  text-align: center;
  margin: 4rem 0;
}

.four-oh-four h1 {
  font-size: 6rem;
}

.contact-details {
  list-style: none;
}

.contact-details img {
  width: 2rem;
}

.contact-details h3 {
  margin: 2rem 0;
}

.contact-details ul {
  margin-top: 3rem;
}

.contact-details li {
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.contact-details li span {
  color: #3a28d4;
  margin-right: 2rem;
  font-size: 2rem;
  display: flex;
  transform: translateY(3px);
}

.contact-details li a {
  font-size: 1.2rem;
}

:root {
  --gutter-large: 2rem;
  --gutter-medium: 1.5rem;
  --gutter-small: 1rem;
}

body, html {
  width: 100%;
  max-width: 100%;
}

svg {
  width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}
/*# sourceMappingURL=index.49bcb175.css.map */
