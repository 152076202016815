@import "reset";
@import "variables";
@import "mixins";
@import "media";
@import "typography";
@import "bp-theme-tweaks.scss";

@import "components/container.scss";
@import "components/section.scss";
@import "components/heading.scss";
@import "components/anniversary-banner";
@import "components/brands-strip.scss";
@import "components/intro.scss";
@import "components/logo.scss";
@import "components/trade.scss";
@import "components/forms.scss";
@import "components/size-chart.scss";
@import "components/splash.scss";
@import "components/404.scss";
@import "components/contact-page.scss";

:root {
  --gutter-large: 2rem;
  --gutter-medium: 1.5rem;
  --gutter-small: 1rem;
}

body,
html {
  width: 100%;
  max-width: 100%;
}

svg {
  width: 100%;
  height: auto;
}

*[hidden] {
  display: none;
}
