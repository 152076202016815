.logo {
  path {
    @include transition();
  }

  &--orange {
    fill: $orange;
  }

  &--blue {
    fill: $blue;
  }

  &:hover {
    .logo--orange {
      fill: $blue;
    }

    .logo--blue {
      fill: $orange;
    }
  }
}
