//
// // BUTTONS
//
.blue-button-style {
  background: $blue;
  color: $white;
}

.orange-button-style {
  background: $orange;
  color: $white;
}

.grey-button-style {
  background: $orange;
  color: $white;
}

button.button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3rem;

  &.b_checkout {
    @extend .orange-button-style;
  }

  &.b_standard {
    @extend .blue-button-style;
  }

  &.b_basket {
    padding: 2rem;
    text-transform: uppercase;
    font-size: 1.1rem;
  }

  &.b_small {
    display: inline-block;
    width: auto;
    height: auto;
    padding: 1rem;
    font-size: 0.9rem;
    text-align: center;
    @extend .orange-button-style;
  }

  &#button_signin {
    @extend .blue-button-style;
  }

  &#button_account {
    @extend .orange-button-style;
  }

  &#notify {
    @extend .blue-button-style;
  }
}

.quickview.button.b_standard {
  height: auto;
  width: 130px;
}

.center {
  section > h1:first-of-type {
    margin-top: 1rem !important;
    text-align: center;
  }
}

//
// // FORMS
//
input[type=checkbox], input[type=radio] {
  width: 1rem;
  height: 1rem;
  border: 1px solid black;
  margin: 0;
  cursor: pointer;

  @include transition();

  &:checked {
    background: $orange;
  }
}

label {
  cursor: pointer;
}

select {
  display: flex;
  align-items: center;
}

.refine input[type=checkbox] {
  margin-right: 0.5rem;
}

.grid.down.apart_s {
  > div {
    display: flex;
    align-items: center;

    input {
      margin-right: 0.5rem;
    }
  }
}

// 
// // NAVIGATION
//
.navigation_bar {
  .navigation_links > ul {
    position: relative;
  }

  .dropdown {
    position: static;
  }

  .dropdown_menu {
    background: transparent;
  
    a {
      font-size: 1.1rem;
    }

    &:before {
      content: "";
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;
      right: 2rem;
      bottom: 2rem;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMDMuODkiIGhlaWdodD0iMTQ1LjI2IiBjbGFzcz0ibG9nbyIgdmlld0JveD0iMCAwIDUzLjk1IDM4LjUiPjxnIGFyaWEtbGFiZWw9IkZSRUVXQVkiPjxwYXRoIGZpbGw9IiNmZjYwMDIiIGQ9Ik0tLjE4LjAxaDcuOTVsMTYuMzggMjUuODRWNC4zN2MwLS42Mi4xLTEuMi4zNC0xLjcyLjIyLS41NS41My0xLjAzLjkzLTEuNDIuMzktLjQuODUtLjY5IDEuMzctLjg4QTQuMDggNC4wOCAwIDAgMSAyOC40NiAwYTQuMzcgNC4zNyAwIDAgMSAzLjgzIDIuM2wxNC45IDIzLjU1VjBoNi42N3YzNS4yMWMwIDEuMDEtLjI2IDEuODEtLjc4IDIuNC0uNTMuNi0xLjIyLjg5LTIuMDYuODktLjc2IDAtMS40OC0uMTUtMi4xNi0uNDQtLjY2LS4zLTEuMjEtLjgtMS42Ny0xLjUyTDMwLjggMTEuOTF2MjMuM2MwIDEtLjI2IDEuOC0uNzggMi40LS41My41OS0xLjIyLjg4LTIuMDYuODgtLjc2IDAtMS40OC0uMTUtMi4xNi0uNDQtLjY2LS4zLTEuMjEtLjgtMS42Ni0xLjUyeiIgY2xhc3M9ImxvZ28tLW9yYW5nZSIvPjxwYXRoIGZpbGw9IiMzYTI4ZDQiIGQ9Ik0xNy4yMiA2Ljc5SDYuNXY5Ljk2aDkuMDJ2Ni42N0g2LjQ5djE0Ljk1SC0uMThWLjEzaDE3LjR6IiBjbGFzcz0ibG9nby0tYmx1ZSIvPjwvZz48L3N2Zz4=);
      background-size: 2.5rem 2.5rem;
      display: flex;
      z-index: 2;
    }
    
    &:after {
      content: "";
      width: 96%;
      height: 50%;
      border-bottom-right-radius: 2rem;
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
      background: white;
      filter: drop-shadow(0px 0px 10px $orange);
    }

    &:before, &:after {
      display: none; 

      @include desktop-up {
        display: block;
      }
    }
  
    .dropdown_wrap {
      @include desktop-up {
        padding: 2rem 2rem 3rem;
        border-bottom-right-radius: 2rem;
        position: relative;
        background: white;
      }

        
      &:after {
        @include desktop-up {
          content: "";
          position: absolute;
          left: 2rem;
          right: 2rem;
          bottom: 1rem;
          background: $blue;
          height: 1px;
        }
      }
    }
  }
} 

// 
// // PRODUCTS
//
.option_icons {
  .option_long.selectable {
    display: flex;
    border-radius: 0.5rem;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey--light;

    @include transition();

    &:hover {
      border: 1px solid $black;
    }
  
    &.selected {
      border: 1px solid $black;
    }
  }

  .strike {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    z-index: 1;
    opacity: 0.75;
    background-image: none;

    &:before {
      content: "";
      width: 2px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      background: red;
      transform: translateX(-50%) rotateZ(45deg);
    }
  }

  &.swatch > div.option_color, 
  &.swatch > div.option_image {
    border-radius: 0.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
  }

  & > div.option_color, 
  & > div.option_image {
    border-radius: 0.5rem;

    &.selectable.selected {
      border: 1px solid $black;
    }
  }
}

#product_list_inner .inner_title a{
  font-size: 1rem;
}

.product_info {
  .prd-cta {
    background: transparent;
  }

  .basket_quantity {
    border-radius: 0.5rem;
  }

  .quantity_add, .quantity_sub {
    width: 8rem;
  }

  h1{
    a {
      font-family: inherit;
      font-size: inherit;
      color: inherit;

      &:hover {
        font-family: inherit;
        font-size: inherit;
        color: inherit;
      }
    }

    font-size: 1.8rem;
  }

  button.button.b_basket {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $orange;
  }

  #additions {
    .apart_s {
      column-gap: 0;
    }

    .qq-upload-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.product_detail { 
  .stock-helper-text {
    margin: 0;
    color: $orange;
    height: 1rem;
  }

  .product_additional {
    font-size: 1.2rem;
  }
 }

#discount {
  width: 100%;
}

.product_price {
  width: 100%
}

.logo-printing {
  &__instructions {

    article {
      margin: 0 0 1.5rem 1.5rem;
      padding: 1.5rem 2rem 1.5rem 3rem;
      background: $grey--xlight;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 4px;
        height: 110%;
        background: $blue;
        z-index: -1;
        top: calc(50% + 1rem);
        left: -4px;
      }

      &:last-child:after {
        display: none;
      }

      h3 {
        margin-bottom: 0.5rem;
        margin: 0;
      }

      p {
        margin-top: 0rem;
      }

      > span {
        width: 3rem;
        height: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 0;
        font-size: 2rem;
        line-height: 1;
        transform: translate(calc(-50% - 4px), -50%);
        background: $blue;
        color: $white;
      }
    }
  }
}

.product_discount {
  background: $grey--xlight;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;

  .discount-table { 
    ul {
      padding: 0;
      list-style: none;
      
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.25rem 0;

        > div {
          display: flex;
          align-items: center;
        }
      }
    }

    &__price {
      font-size: 1.1rem;
    }

    &__percentage {
      margin-left: 1rem;
      padding: .1rem .75rem;
      border-radius: 1rem;
      background: $blue;
      color: white;
      font-size: 0.7rem;
    }

    &__sup {
      color: $grey--dark;
    }
  }
}

.inner_description p {
  font-size: 0.9rem;
}

.copyright a span {
  display: none;
}

.cb_title {
  font-family: "heading";
}

.block.block_latest_products {
  margin-top: 4rem;
}

.basket_quantity {
  .quantity_sub, .quantity_add {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .pquantity {
    color: $blue;
  }
}

.product_selling {
  display: flex;
  align-items: end;

  #price_selling {
    margin-right: 1rem;
  }

  .selling_price {
    font-size: 1.6rem;
    color: $orange;
  }
}

// Hide bluepark reference
#cb_id_CONTENT > table.tables td:nth-child(2){
  display: none;
}