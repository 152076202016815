.anniversary-banner {
  padding: 4rem 0;
  position: relative;
  background: $black;

  &__inner {
    display: flex;
    align-items: center;

    > div {
      display: flex;
      position: relative;
    }
  }

  h2 {
    font-family: "heading";
    font-size: 8rem;
    line-height: 1;
    margin: 0;
    padding: 0 2rem 2.5rem 0;

    @include tablet-up {
      font-size: 11rem;
      padding: 0 4rem 3rem 0;
    }

    @include desktop-up {
      font-size: 14rem;
    }
  }

  .anniversary-banner__prefix, .anniversary-banner__suffix {
    margin: 0;
    position: absolute;
    color: $white;
  }

  .anniversary-banner__prefix {
    top: 26px;
    right: -2.25rem;
    font-size: 2rem;
    color: $blue;
    transform: rotate(-90deg);

    @include tablet-up {
      top: 40px;
      right: -2rem;
      font-size: 2.7rem;
    }

    @include desktop-up {
      font-size: 3.4rem;
      top: 55px;
      right: -3.5rem;
    }
  }

  .anniversary-banner__suffix {
    bottom: 0;
    font-size: 2.3rem;
    left: 0;
    right: 0;
    text-align: center;
    color: $white;

    span, i {
      display: inline-block;
      font-style: normal;
      
      @include transition();
    }

    span {
      -webkit-text-stroke: 1px $blue;
    }

    i {
      -webkit-text-stroke: 1px $orange;
    }

    &:hover {
      span {
        -webkit-text-stroke: 1px $orange;
      }

      i {
        -webkit-text-stroke: 1px $blue;
      }
    }



    @include tablet-up {
      font-size: 3.2rem;
      left: -11px;
    }

    @include desktop-up {
      font-size: 4rem;
      left: -5px;
      bottom: -1rem;
    }
  }
}