.section {
  min-height: 90vh;
  padding: $spacing--large 0;
  background: $white;

  @include tablet-up {
    min-height: 40vh;
    margin-top: $spacing--large;
  }

  @include desktop-up {
    min-height: 75vh;
  }
}

.section--image {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;

  .section__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 40rem;
    padding: $spacing--medium var(--gutter-large);
    margin: (-$spacing * 2) 0 0 0;
    position: relative;
    z-index: 1;
    background: white;
    box-shadow: 0rem 0 2rem $grey--light;
    overflow: hidden;

    @include tablet-up {
      min-height: 50vh;
      padding: $spacing--medium $spacing;
    }

    @include desktop-up {
      width: 50%;
      margin: 0 0 0 $spacing;
      padding: $spacing--medium $spacing * 1.5;
    }

    @include large-desktop-up {
      padding: $spacing--medium $spacing * 2;
    }

    &-cta {
      display: flex;
      flex-direction: column;
      align-items: center;

      .button {
        width: fit-content;
      }

      .anchor {
        margin-top: var(--gutter-small);

        @include mobile-up {
          margin-top: 0.5rem;
          margin-left: var(--gutter-small);
        }
      }

      @include mobile-up {
        flex-direction: row;
      }
    }
  }

  .section__image {
    width: 100%;
    height: 50vw;
    background-size: cover;
    background-position: center;

    @include desktop-up {
      width: 50%;
      height: auto;
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  &-reversed {
    .section__content {
      @include desktop-up {
        margin: 0 $spacing 0 auto;
      }
    }

    .section__image {
      right: auto;
      left: 0;
    }
  }
}
