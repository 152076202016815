.contact-details {
    list-style: none;

    img {
        width: 2rem;
    }

    h3 {
        margin: 2rem 0;
    }

    ul {
        margin-top: 3rem;
    }

    li {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        span {
            display: flex;
            margin-right: 2rem;
            font-size: 2rem;
            transform: translateY(3px);
            color: #3a28d4;
        }

        a {
            font-size: 1.2rem;
        }
    }
}