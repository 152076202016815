.heading {
  h2, h3 { 
    position: relative;
    font-family: "heading";
  }

  &--align {
    &-center {
      text-align: center;
    }
    &-right {
      text-align: right;
    }
  }
}
