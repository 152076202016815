$large-desktop-width: 1200px;
$desktop-width: 960px;
$tablet-width: 700px;
$mobile-width: 400px;

@mixin large-desktop-up {
  @media (min-width: #{$large-desktop-width}) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin mobile-up {
  @media (min-width: #{$mobile-width}) {
    @content;
  }
}
