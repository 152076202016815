// @import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap");

html {
  font-size: 90%;

  @include tablet-up {
    font-size: 100%;
  }
}

body {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8;
  color: $black;
}

h1 {
  font-family: "heading";
  margin: 1rem 0;
}

h2,
h3,
h4,
h5 {
  color: $orange;
}

h1 {
  font-size: 2.2rem;
  color: $blue;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.4rem;
}

p,
ul,
ol {
  margin-bottom: calc($spacing / 2);
}

b {
  font-weight: 600;
}

a {
  cursor: pointer;

  &.anchor {
    color: $blue;
    font-size: inherit;
    font-weight: normal;
    text-decoration: underline;
    @include transition;

    &:hover {
      color: $orange;
    }

    &:visited {
      color: $blue;
    }
  }
}

.button--text {
  position: relative;
  color: $orange;
  overflow: hidden;
  text-decoration: none;
  @include transition;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: $blue;
  }
}

ol,
ul {
  padding-left: var(--gutter-large);
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

del {
  text-decoration: line-through;
}

strong {
  font-weight: 800;
}

pre {
  font-family: monospace;
  white-space: pre;
}

em {
  font-style: italic;
}

hr {
  margin: var(--gutter-large) 0 $spacing--medium;
  border-bottom: 2px solid $grey--light;
}

a.button, button.button {
  cursor: pointer;
  display: inline-flex;
  padding: 0.75rem 1.5rem;
  margin-top: 0.75rem;
  font-weight: 600;
  color: $white;
  text-decoration: none;
  @include transition;
  // border-radius: 0.25rem;
  // border: 1px solid transparent;

  &:hover {
    text-decoration: none;
    //border: 1px solid $grey--light;
  }

  &--primary {
    background: $blue;
    color: $white;

    &:hover {
      background: $orange;
    }
  }

  &--secondary {
    background: $orange;
    color: $white;

    &:hover {
      background: $blue;
    }
  }
}
