.splash {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55vh;
    padding: 0 var(--gutter-large);
    position: relative;
    overflow: hidden;
  
    @include tablet-up {
      padding-top: var(--header-height-large);
    }
  
    &__video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: var(--header-height-small);
      left: 0;
      z-index: 0;
      object-position: 80%;
  
      @include tablet-up {
        top: var(--header-height-large);
        object-position: center;
      }
    }
  
    &__phone {
      position: absolute;
      top: 2rem;
      right: 2rem;
      font-size: 1.25rem;
      z-index: 2;
  
      a {
        text-decoration: none;
        color: $black;
      }
    }
  
    &__logo {
      width: 30rem;
      max-width: 95%;
      margin: auto;
      position: relative;
      z-index: 2;
  
      &-sup {
        font-size: 3.3vw;
        font-weight: 600;
        text-align: center;
  
        @include mobile-up {
          font-size: 3.2vw;
        }
  
        @include tablet-up {
          font-size: 1.5rem;
        }
      }
  
      svg {
        filter: drop-shadow(0px 0px 1rem white);
      }
    }
  }