.prd-page-details {
    min-width: 0;
}

.size-chart {
    overflow-x: scroll;
    text-align: center;
    
    table {
        min-width: 100%;
        margin-bottom: $spacing--medium;

        thead {
            background: $grey--xlight;
        }

        th, td {
            padding: 0.5rem 1rem;
            white-space: nowrap;
        }
    }
}