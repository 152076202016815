.container {
  display: flex;
  flex-direction: column;
  width: $container-width;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 var(--gutter-small);

  @include tablet-up {
    padding: 0 var(--gutter-large);
  }

  &--narrow {
    width: 50rem;
  }
}
