.intro {
  position: relative;
  background: white;
  font-size: 1rem;

  &__top {
    height: 100vh;
    width: 100%;
    max-width: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
    overflow: hidden;
    transform: translateY(-95%);

    &:before {
      content: "";
      height: 75%;
      width: 200vw;
      transform: rotate(5deg) translate(-50%, 0);
      position: absolute;
      bottom: calc(6rem - 1px);
      left: 50%;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 100%) 10%,
        rgba(255, 255, 255, 50%) 60%,
        rgba(255, 255, 255, 0) 100%
      );

      @include tablet-up {
        bottom: calc(-3rem - 1px);
      }
    }

    &:after {
      content: "";
      width: 200vw;
      height: 50%;
      transform: rotate(5deg) translate(-50%, 100%);
      position: absolute;
      bottom: 6rem;
      left: 50%;
      background: white;

      @include tablet-up {
        bottom: -3rem;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    padding: 0 var(--gutter-small);
    margin: 0 auto;
    position: relative;
    z-index: 2;
    background: white;
    color: black;
    font-size: 1.2rem;
  }
}
