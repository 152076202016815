.brands-strip {
  display: flex;
  align-items: center;
  justify-content: center;

  @include tablet-up {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-bottom: 0;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;

    @include tablet-up {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 50rem;
    }

    @include tablet-up {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      width: 65rem;
    }

    li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: var(--gutter-small);
      opacity: 1;
      @include transition();

      &:hover {
        opacity: 0.5;
      }

      @include tablet-up {
        padding: var(--gutter-small);
      }

      @include desktop-up {
        width: auto;
        height: calc(6rem + (var(--gutter-small) * 2));
        padding: var(--gutter-small) var(--gutter-large);
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      img {
        width: 70%;
        height: auto;
        max-height: 7rem;
        max-width: 14rem;

        @include desktop-up {
          width: 100%;
          max-height: 6rem;
        }
      }
    }
  }
}
