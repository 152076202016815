.form {
  width: 100%;
  max-width: 800px;
  margin: $spacing--medium auto;
  padding: 0;
  text-align: left;

  @include tablet-up {
    padding: 0 var(--gutter-large);
  }

  &-fieldset--two-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--gutter-small);
  }

  &__submitted {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25rem;
    margin: $spacing--medium auto;
    padding: $spacing;
    text-align: center;

    p {
      margin: 0;
      font-weight: 500;
    }

    .round-check-icon {
      width: 10rem;
      path {
        fill: green;
      }
    }
  }

  .heading {
    text-align: center;
  }

  h3 {
    margin: $spacing--medium 0 0 0;
  }

  label {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    color: $grey--dark;
    font-weight: 500;
    line-height: 1.1;
    cursor: pointer;
    @include transition;

    span {
      color: $orange;
      font-size: 1.5em;
    }

    p {
      display: block;
      font-weight: 400;
      color: $grey--medium;
      font-style: italic;
    }
  }

  .input,
  textarea {
    padding: 0.5rem;
    font-family: inherit;
    border-bottom: solid 2px $grey--light;
    box-shadow: none;
    background: transparent !important;
    @include transition;

    &:focus {
      border-bottom: solid 2px $orange;

      + label {
        color: $orange;
        font-weight: 600;
      }
    }

    // &:required + label:after {
    // TODO
    // }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $grey--light;
      opacity: 0.6;
      font-weight: 400;
      font-style: italic;
    }
  }

  .input {
    width: 100%;
  }

  input[type="number"] {
    text-align: center;
  }

  fieldset {
    h3 {
      margin-top: $spacing--medium;
    }
  }

  textarea {
    width: 100%;
    height: 150px;
    resize: vertical;
  }

  .field {
    display: flex;
    flex-direction: column-reverse;
    margin-top: $spacing;

    &--name {
      display: none;
    }

    &--checkbox {
      flex-direction: row;

      label {
        margin: 0;
      }
    }
  }

  .checkbox {
    display: flex;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    margin-right: var(--gutter-small);
    border: 3px solid $black;
    position: relative;
    cursor: pointer;

    &--checked:after {
      content: "";
      position: absolute;
      inset: 3px;
      background: $orange;
    }
  }
}
