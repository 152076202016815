.trade {
  &__no-account {
    display: block;
    margin-top: 1rem;
    box-shadow: none;

    @include tablet-up {
      margin-top: 0;
      margin-left: 1rem;
      display: inline-block;
    }

    @include desktop-up {
      margin: 0 0 0 0.75rem;
    }
  }

  .modal {
    position: fixed;
    inset: 0;
    z-index: $modal-z-index;

    &__close {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      font-weight: 500;
      padding: $spacing;
      color: $grey--dark;
      @include transition();

      &:hover {
        color: $orange;
      }
    }

    &__viel {
      cursor: pointer;
      position: absolute;
      inset: 0;
      z-index: $modal-viel-z-index;
      background: $black;
      opacity: 0.75;
    }

    &__content {
      width: 100%;
      max-width: 85vw;
      height: auto;
      margin: auto;
      padding: $spacing;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: $modal-content-index;
      background: white;

      @include mobile-up {
        width: 30rem;
      }

      &-buttons {
        display: flex;
        justify-content: center;
      }
    }
  }
}
